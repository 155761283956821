<template>
    <div v-if="perms.includes('Facturation Clients') || perms.includes('Admin')">
     <div v-if="loading">
        <div id="loading-bg">
        <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
    <b-card v-else >
        <!-- input search -->
        <div class="custom-search">
        <b-row>
            <b-col md="3" >
            <b-form-group>
                <b-button
                variant="primary"
                 v-b-toggle href="#add-client-sidebar" @click.prevent
            >
                Nouveau Client
            </b-button>
            </b-form-group>
            </b-col>
            <b-col md="9">
            <b-form-group>
            <div class="d-flex align-items-center">
            <label class="mr-1">Chercher</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Chercher"
              type="text"
              class="d-inline-block"
            />
            </div>
        </b-form-group>
            </b-col>
        </b-row>
        </div>
        <!-- <div class="custom-search d-flex justify-content-end">
            <b-button
                variant="primary"
                :to="{ name: 'facture-fournis-add'}"
            >
                Nouvelle Facture
            </b-button>
        <b-form-group>
            <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
            />
            </div>
        </b-form-group>
        </div> -->

        <!-- table -->
        <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        responsive
        :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        <template
            slot="table-row"
            slot-scope="props"
        >

            <!-- <div
            v-if="props.column.field === 'NumFact'"
            class="text-nowrap"
            >
            <span ># {{ props.row.NumFact }}</span>
            </div> -->
            


            <!-- Column: Action -->
             <!-- <span v-else-if="props.column.field === 'stock'">
                     <b-button
                        variant="gradient-info"
                        class="btn-icon"
                        @click="$router.push({ name: 'voir-stock-product', params: { id: props.row.id }})"
                        >
                        <feather-icon icon="EyeIcon" />
                    </b-button>
             </span> -->
           <span v-if="props.column.field === 'action'">
            <b-row>  <b-button style="margin-right: 6px;margin-left: 8px;"
                        variant="gradient-success"
                        class="btn-icon"
                        v-b-toggle href="#edit-client-sidebar" @click.prevent @click="showUpdate(props.row)"
                        >
                        <feather-icon icon="Edit2Icon" />
                        
                    </b-button>
                    <b-button
                        variant="gradient-danger"
                        class="btn-icon"
                         @click="DeleteClient(props.row.id)"
                        >
                        <feather-icon icon="TrashIcon" />
                        
                    </b-button></b-row>
                    
                    
            <!-- <span>
                <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                >
                <template v-slot:button-content>
                    <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                    />
                </template>

                <b-dropdown-item  >
                   
             <b-button 
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    v-b-toggle href="#edit-client-sidebar" @click.prevent @click="showUpdate(props.row)">
                    <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"                
                    />
                   <span>Modifier</span> 
             </b-button>
                    
                </b-dropdown-item>
                <b-dropdown-item>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                     @click="DeleteClient(props.row.id)"
                    >
                    <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                    
                    />
                    <span>Supprimer</span>
                    </b-button>
                </b-dropdown-item>
           
                </b-dropdown>
            </span> -->
            </span>

            <!-- Column: Common -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Showing 1 to
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>

    </b-card>
  
  <div>
<b-sidebar 
    id="add-client-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right 
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajouter Nouveau Client
        </h5>
<!-- {{countries}} -->
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >

        <b-form-group
          label="Nom Société"
          label-for="clientname"
        >
          <b-form-input
            id="clientname"
            v-model="NomSociete"
            trim
            placeholder="Société"
          />
        </b-form-group>

         <b-form-group
          label="ICE"
          label-for="clientref"
        >
          <b-form-input
            id="clientref"
            v-model="ice"
            trim
            placeholder="ICE"
          />
        </b-form-group>
        
        <b-form-group
          label="Téléphone"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="phone"
            trim
            placeholder="Téléphone"
            type="number"
          />
        </b-form-group>

        <b-form-group
          label="Adresse"
          label-for="adresse"
        >
          <b-form-input
            id="adresse"
            v-model="adresse"
            trim
            placeholder="Adresse"
          />
        </b-form-group>

        

        
       
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="AddClient()"
          >
            Ajouter
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="Reset()"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>

  <b-sidebar 
    id="edit-client-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right 
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Modifier Ce client
        </h5>
<!-- {{countries}} -->
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
           
        <b-form-group
          label="N° Client"
          label-for="numclient"
        >
          <b-form-input
            id="numclient"
            v-model="form.NumClient"
            trim
            disabled
          />
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="Nom Société"
          label-for="clientname"
        >
          <b-form-input
            id="clientname"
            v-model="form.NomSociete"
            trim
          />
        </b-form-group>

        
        <b-form-group
          label="ICE"
          label-for="Ice"
        >
          <b-form-input
            id="Ice"
            v-model="form.Ice"
            trim
          />
        </b-form-group>

        <b-form-group
          label="Téléphone"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="form.phone"
            trim
            type="number"
          />
        </b-form-group>

         <b-form-group
          label="Adresse"
          label-for="adresse"
        >
          <b-form-input
            id="adresse"
            v-model="form.adresse"
            trim
          />
        </b-form-group>

       
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="UpdateClient(form.id)"
          >
            Modifier
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="Reset()"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
  </div>

    <!--   <sidebar-add-product /> -->
</div>
<div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard,BRow, BCol,BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, 
  BDropdownItem,BTooltip,BButton,VBToggle,BCollapse,BSidebar,BForm
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
//  import SidebarAddProduct from './SidebarAddProduct.vue'
import vSelect from 'vue-select'
import NotAuthoriz from '../NotAuthoriz.vue'


export default {
      directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
      BCard,BCollapse,
    BCardCode,BRow, BCol,
    VueGoodTable,
    BAvatar,vSelect,BSidebar,BForm,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BButton,NotAuthoriz
    // SidebarAddProduct,
  },
  data() {
    return {
      perms:[],
        loading : false,
        fournis:[],
        Products:[],
        fourniID:{},
        ice:'',
        NomSociete:'',
        phone:'',
        adresse:'',
        form :new Form({    
          id:0,
          NomSociete:'',
          NumClient:'',
          Ice:'',
          adresse:'',
          phone:'',
          }),
          
          showSide:true,
      pageLength: 10,
      dir: false,
      columns: [
           {
          label: 'Client N°',
          field: 'NumClient',
          filterOptions: {
            enabled: true,
            placeholder: 'N° facture.. ',
          },
        },
        {
          label: 'ICE',
          field: 'Ice',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Ice',
          },
        },
        {
          label: 'Client',
          field: 'NomSociete',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher TotalHT',
          },
        },
        {
          label: 'Téléphone',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher..',
          },
        },
        {
          label: 'Adresse',
          field: 'adresse',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher..',
          },
        },
        
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      UserID:{},
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        cheque : 'light-success',
        ESPECE     : 'light-warning',
        CHEQUE : 'light-success',
        espece     : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
        this.perms=JSON.parse(localStorage.getItem('access'));
        this.getClients()
        this.$http.get(`${config.API_BASE_URL}/api/auth/getAllFournis`).then(res => { this.fournis = res.data })
        this.UserID=JSON.parse(localStorage.getItem('userData'))
    },
  methods:{
    getClients(){
      this.loading = true
        this.$http.get(`${config.API_BASE_URL}/api/auth/getClients`).then(res => { this.rows = res.data ,this.loading = false })
      },
    showUpdate(com){
        this.form.reset();
        this.form.fill(com);
        console.log(com)
        },
    Reset(){
        document.getElementById("formdata").reset();
      },
    AddClient(){
        let data= new FormData;
          data.append('UserID', this.UserID.id);
          data.append('NomSociete', this.NomSociete);
          data.append('ice', this.ice);
          data.append('phone', this.phone);
          data.append('adresse', this.adresse);
          console.log(Object.fromEntries(data))
        this.$http.post(`${config.API_BASE_URL}/api/auth/AddClients`,data) .then(res => {   
            if(res.data=='Success'){
                 document.getElementById("formdata").reset();
                this.getClients()
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Client est bien sauvegardé',
                showConfirmButton: false,
                timer: 1000 })
                }      
        })
        .catch(() => {
            // console.log("Error......")
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        }) 
        },
    UpdateClient(id){
        let data= new FormData;
          data.append('UserID', this.UserID.id);
          data.append('NomSociete', this.form.NomSociete);
          data.append('Ice', this.form.Ice);
          data.append('phone', this.form.phone);
          data.append('adresse', this.form.adresse);
        this.$http.post(`${config.API_BASE_URL}/api/auth/EditClient/`+this.form.id,data) .then(res => {   
            if(res.data=='success'){
                this.getClients()
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Client est bien modifié',
                showConfirmButton: false,
                timer: 1000 })
                }      
        })
        .catch(() => {
            // console.log("Error......")
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        }) 
    },
    DeleteClient(id){
      let data= new FormData;
      data.append('UserID', this.UserID.id);
      this.$swal.fire({
          text: "Vous voulez supprimer ce client ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, supprimer!'
        }).then((result) => {
          if (result.value) {
              this.$http.post(`${config.API_BASE_URL}/api/auth/DeleteClient/${id}`,data)
                .then(res=> {
                      if(res.data=='Success'){
                      this.$swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Client est bien supprimé',
                      showConfirmButton: false,
                      timer: 1500  })   
                      this.getClients(); 
                    }else if(res.data=='error'){
                        this.$swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: 'Vous pouvez pas supprimer ce client, Il a une facture. '
                        });
                    }    
                }).catch(() => {
                    this.$swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: 'Something went wrong! Pleaz try again'
                        });
                })
            }             
        }) 
    }
  }
}
</script>
<style scoped>
a.btn.btn-icon.btn-gradient-success.collapsed {

    margin-right: 6px;
    margin-left: 8px;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
